html{
  scroll-behavior: smooth;
}

@import "aos/dist/aos.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.terminal-box {
  background: #000;
  padding: 20px;
  overflow-y: auto;
  max-height: 300px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.inputLine input {
  background: #000;
  border: none;
  border-bottom: 1px solid #777;
}

@keyframes blink {
  0% {opacity: 0;}
  50% {opacity: 1;}
  100% {opacity: 0;}
}

.blink {
  animation: blink 1s infinite;
}

.main-content.hidden {
  visibility: hidden;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.fade-in-animation {
  animation: fadeIn 1s forwards;
}

/* For Webkit browsers like Chrome and Safari */
.bg-gray-200::-webkit-scrollbar {
  width: 10px;  /* width of the entire scrollbar */
}

.bg-gray-200::-webkit-scrollbar-track {
  background: rgb(255, 255, 255);  /* color of the tracking area */
}

.bg-gray-200::-webkit-scrollbar-thumb {
  background-color: rgb(0, 0, 0);  /* color of the scroll thumb */
  border-radius: 20px;  /* roundness of the scroll thumb */
  border: 2px solid transparent;  /* creates padding around scroll thumb */
  background-clip: content-box;  /* this will make the thumb shorter */
}

/* For Firefox */
.bg-gray-200 {
  scrollbar-width: thin; 
  scrollbar-color: darkgray lightgray; 
}
