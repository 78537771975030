.splash-bg {
  background-image: url('../assets/splashpic_mobile.svg'); /* Mobile optimized SVG */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

/* Switch to desktop SVG for larger screens */
@media (min-width: 768px) {
  .splash-bg {
    background-image: url('../assets/splashpic.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}



/* Default state */
.fixed {
  transform: translateY(0);
  transition: transform 1s ease;
}

/* Slide splash upwards */
.slide-up {
  transform: translateY(-100%);
}

/* For smooth transition without delay, ensure that main content is set to fill up right away */
.App {
  transform: translateY(0);
  transition: transform 1s ease;
}